svg.tooth {
  cursor: pointer;
  fill: white;
}

svg.tooth .estetica-to-do {
  stroke-width: 1.5;
  stroke: #000;
  fill: #ef4444;
  fill-opacity: 0.5;
  stroke-dasharray: 2;
  stroke-linejoin: round;
}

svg.tooth .estetica-done {
  stroke-width: 1.5;
  stroke: #000;
  fill: #0284c7;
  fill-opacity: 0.5;
  stroke-dasharray: 2;
  stroke-linejoin: round;
}

svg.tooth .to-do {
  fill: #ef4444;
}

svg.tooth .done {
  fill: #3b82f6;
}

svg.tooth .active {
  fill: gray;
}

svg.tooth polygon {
  stroke: black;
  stroke-width: 0.5;
  margin-top: 40px;
}

polygon:hover {
  fill: #dcfce7;
}

rect:hover {
  fill: lightgray;
}

.tooth-wrapper {
  margin: 0 2px;
  position: relative;
}

.flex-container-justify {
  display: flex;
  justify-content: space-around;
}

.flex-container-right {
  display: flex;
  justify-content: flex-end;
}

.flex-container-left {
  display: flex;
  justify-content: flex-start;
}

.tooth {
  font-size: 6pt;
  font-weight: normal;
}
