.navbar {
  font-family: "Raleway", sans-serif;
  font-style: bold;
  background: linear-gradient(90deg, #3f8ccb, #129fda);
  height: 3.6em;
  display: flex;
  justify-content: center;
  box-shadow: 5px 5px 5px grey;
  align-items: start;
}

.navbar-logo {
  color: #fff;
  margin-right: 1rem;
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: flex;
  grid-template-columns: repeat(5, auto);
  list-style: none;
  text-align: left;
  width: 70vw;
  justify-content: center;
  margin-right: 2rem;
}

.nav-item {
  display: flex;
  align-items: left;
  height: 35px;
  padding-bottom: 2em;
}

.nav-links {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 2.5rem;
}

.nav-links:hover {
  background-color: #0ea5e9;
  color: white;
  border-radius: 4px;
  transition: all 0.2s ease-out;
  text-decoration: none !important;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 1024px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 80vh;
    position: absolute;
    top: 76px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    gap: 2.5em;
  }

  .nav-menu.active {
    background: linear-gradient(90deg, #3f8ccb, #129fda);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 20;
  }

  .nav-links {
    text-align: left;
    padding: 1rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #10b981;
    border-radius: 50;
    height: 1rem;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(7%, 25%);
  }

  .menu-icon {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 50%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .user_name {
    display: none;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: left;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #1888ff;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
    padding-bottom: 1.5em;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #6f99d9;
    transition: 250ms;
  }
}
