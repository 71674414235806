@page {
  size: Legal portrait; /* Ensures the page is A4 size in portrait orientation */
  margin: 2cm 1cm; /* Adjust margins as needed */
  margin-bottom: 1cm;
}
@media print {
  body {
    margin: 0;
    padding: 0;
    box-shadow: none;
    overflow: visible; /* Ensures no scrollbars are displayed */
  }

  .header {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: white; /* Ensures header background is white */
  }

  .footer {
    position: fixed;
    bottom: 0.5cm;
    width: 100%;
    background-color: white; /* Ensures header background is white */
  }

  .content {
    margin-top: 5rem; /* Adjust this value to accommodate header height */
  }

  .page-break {
    page-break-before: always; /* Forces a page break before the element */
    display: block;
    height: 0;
    margin-top: 6rem;
  }

  .MuiAvatar-root {
    border-radius: 50% !important;
  }

  /* Remove borders and scrollbars */
  *,
  *::before,
  *::after {
    box-shadow: none !important;
    overflow: visible !important;
  }
}
