:root {
  --buttonBgColor: #ef4444;
  --fc-today-bg-color: #f5f5f5;
  --fc-border-color: #71717a;
  --fc-daygrid-event-dot-width: 12px;
  --fc-today-state-enabled: true;

  --fc-button-text-color: #fff;
  --fc-button-bg-color: var(--buttonBgColor);
  --fc-button-border-color: #2c3e50;
  --fc-button-hover-bg-color: #71717a;
  --fc-button-hover-border-color: #1a252f;
  --fc-button-active-bg-color: #71717a;
  --fc-button-active-border-color: #151e27;

  --fc-list-event-dot-width: 10px;
  --fc-list-event-hover-bg-color: #f5f5f5;
  --fc-page-bg-color: #f5f5f5;

  --fc-event-bg-color: #fff;
  --fc-event-border-color: #fff;
  .fc .fc-multimonth-multicol .fc-multimonth-month {
    padding: 0px 0.5em 0.5em;
  }

  .fc .fc-toolbar-title {
    font-size: 1.2em;
    margin: 0px;
  }

  .fc .fc-multimonth-title {
    font-size: 1.2em;
    font-weight: 400;
    padding: 1em 0px;
    text-align: center;
  }

  .fc .fc-col-header-cell-cushion {
    display: inline-block;
    padding: 4px 4px;
    font-weight: 400;
  }

  .fc-day-sun {
    background-color: #fce4ec;
    /*your styles goes here*/
  }
  .fc-button {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: var(--buttonBgColor);
    border: 1px solid #2c3e50;
    padding: 0.4em 0.65em;
    font-size: 0.9em;
    line-height: 1.5;
    border-radius: 0.25em;
  }
  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0;
  }
}
