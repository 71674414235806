body {
  font-family: "Open Sans", sans-serif;
  background-color: #f5f5f5;
  min-height: 100%;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.container-pacientes {
  margin-top: 10px;
  margin-left: 30px;
  width: 85%;
}

.label-title {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  color: #07689f;
  margin-top: 15px;
}

.form-control {
  background: #eff6ff;
  margin-top: 15px;
  margin-left: 5px;
  padding: 9px 5px;
  font-style: oblique;
  font-size: 17px;
  color: #696969;
  border-radius: 0.25rem;
  border: 1px solid #1e40af;
}

.pacientes-table td,
.pacientes-table th {
  border: 0px solid dimgrey;
  padding: 0.5em;
}

.pacientes-table tr:nth-child(even) {
  background-color: #f4f4f2;
  color: black;
}
.pacientes-table tr:nth-child(odd) {
  background-color: #e8e8e8;
  color: black;
}

.pacientes-table tr:hover {
  background-color: #ceddf3;
}

.pacientes-table tr {
  line-height: 20px;
}

.pacientes-table th {
  line-height: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: left;
  background-color: #9a9a9a;
  color: white;
}

.page-item,
.page-link,
.active.page-item {
  color: #07689f;
  background-color: #e2e8f0;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0284c7;
  border-color: #64748b;
}

.btnlogin {
  background-color: #07689f;
  border-radius: 0.25rem;
  font-size: 20px;
  font-weight: bold;
  color: #f5f5f5;
  margin-left: 20px;
  margin-top: 15px;
  width: 150px;
  height: 40px;
  border: none;
}

.btn-close:after {
  content: "\0078";
  color: #fff;
  font-size: 28px;
  position: relative;
  left: -3px;
  top: -3px;
  border: none;
  outline: none;
}

.loading1 {
  margin-top: 15px;
}

.loading2 {
  margin-top: 10px;
  margin-left: 15px;
}

.even {
  background-color: #ebe7b7;
}
.odd {
  background-color: #e896af;
}
.wrapper {
  display: grid;
  grid-gap: 20px;
  margin-top: 0px;
  grid-template-areas:
    "header"
    "nav"
    "content"
    "sidebar"
    "ad"
    "footer";
}

.main-head {
  grid-area: header;
  margin-top: 10px;
  margin-left: 30px;
  color: #07689f;
}
.content {
  grid-area: content;
}
.main-nav {
  grid-area: nav;
}
.side {
  grid-area: sidebar;
}
.ad {
  grid-area: ad;
}
.main-footer {
  grid-area: footer;
}

@media only screen and (max-width: 420px) {
}

@media screen and (max-width: 700px) {
  .wrapper {
    grid-template-columns: 1fr 4fr 1fr;
    grid-template-areas:
      "header header  header"
      "nav    content sidebar"
      "nav    content ad"
      "footer footer  footer";
  }

  .main-head {
    grid-area: header;
    color: #07689f;
    margin-left: -10px;
  }

  nav ul {
    flex-direction: column;
  }
}

@media screen and (max-width: 650px) {
  .wrapper {
    grid-template-columns: 1fr 3fr;
    grid-template-areas:
      "header  header"
      "nav     nav"
      "sidebar content"
      "ad      footer";
  }

  .main-head {
    grid-area: header;
    color: #07689f;
    margin-left: -20px;
  }

  nav ul {
    display: flex;
    justify-content: space-between;
  }
}
