
.dropdown-pacientes {
  width: 200px;
  position: relative;
  top: 68px;
  list-style: none;
  text-align: start;
  z-index: 999;
}

.dropdown-pacientes li {
  background: #5282c6;
  cursor: pointer;
  color: white;
  border-radius: 4px;
}

.dropdown-pacientes li:hover {
  background-color: #6f99d9;
  color: white;
  border-radius: 4px;
  transition: all 0.2s ease-out;
  text-decoration: none !important
}

.dropdown-pacientes.clicked {
  display: none;
}

.dropdown-link {
  border-radius: 4px;
  display: block;
  height: 100%;
  width: 100%;
  text-decoration:  none !important;
  color: #fff;
  padding: 0.75rem;
  text-align: center;
}

.dropdown-link:hover {
  background-color: #6f99d9;
  color: white;
  border-radius: 4px;
  transition: all 0.2s ease-out;
  text-decoration: none !important
}

@media screen and (max-width: 960px) {
  .fa-caret-down {
    display: none;
  }
}
